import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Respuesta } from '../libs/respuesta';
import { CBLService } from '../servicios/cbl.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
/// Code for REcaptcha
import { ReCaptcha2Component } from 'ngx-captcha';
import { EmpleadoService } from '../servicios/empleado.service';
/// Code for REcaptcha End

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  /// Code for REcaptcha
  aFormGroup: FormGroup;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
  idEmpleado: string;
  /// Code for REcaptcha Ends
  constructor(private router: Router,
    private actrouter: ActivatedRoute,
    private cbl: CBLService,
    private messageService: MessageService,
    private empleadService:EmpleadoService,
    /// Code for REcaptcha
    private formBuilder: FormBuilder) {
      /// Code for REcaptcha end
     }

  public password: string;
  public emp_name: string = '';
  public empresa_name: string = '';
  code: string = '';

  public angularProjectVersion:string="";

  display: boolean = false;

  /// Code for REcaptcha
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'normal';
  public lang = 'es';
  public type: 'image' | 'audio';
 public recaptchaSuccess = false;
 //public recaptchaSuccess = true; 
  /// Code for REcaptcha Ends
resetPassword=false;
  ngOnInit(): void {
    document.body.className = 'bg-body';

    this.code = this.actrouter.snapshot.paramMap.get('id');
    this.password = this.actrouter.snapshot.paramMap.get('key');
    this.idEmpleado = this.actrouter.snapshot.paramMap.get('empId');
console.log("idEmpleado",this.idEmpleado)
console.log("password",this.password)
    console.log(this.code); 

    if (this.code != null && this.password != null) {
      this.cbl.loginEmpleado(this.code, this.password, this.idEmpleado).then((r) => {
        console.log(r);
        this.router.navigate(['/index']);
      }).catch((err) => {
        console.log(err);
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Contraseña no válida!' });
      });
    } else if (this.code != null) {
      this.cbl.preloginEmpleado(this.code).then((r) => {
        console.log(r);
        this.emp_name = r.resp['empleado'];
        this.empresa_name = r.resp['Cliente'];
        this.cbl.name_cliente = this.empresa_name;
      });
    }

    this.actrouter.queryParams.subscribe(params => {
      console.log(params);
    });
    /// Code for REcaptcha
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
    /// Code for REcaptcha End


    let angularProjectVersion =this.cbl.angularProjectVersion;
    console.log("ddddddddddddddd",angularProjectVersion);
    this.angularProjectVersion =""+angularProjectVersion+"";
   // alert(angularProjectVersion);

  }

  ngOnDestroy() {
    document.body.className = '';
  }


  login() {
    /// Code for REcaptcha
    if (this.recaptchaSuccess) {
      /// Code for REcaptcha Ends
      console.log(this.code, this.password);
      this.cbl.loginEmpleado(this.code, this.password).then((r) => {
        console.log("cccccccccccc",r);
        this.router.navigate(['/index']);
      }).catch((err) => {
        console.log(err);
        this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'Contraseña no válida!' });
      });
    }
    else {
      this.messageService.add({ severity: 'warn', summary: 'ERROR', detail: 'Por favor confirme reCaptcha!' });
    }
  }

  rgpd() {
    this.display = true;
  }

  /// Code for REcaptcha
  handleSuccess(data) {
    console.log(data);
    this.recaptchaSuccess = true;
  }
  /// Code for REcaptcha End
  view_recordar_old() {
    this.resetPassword=false;
    this.empleadService.resetPassword(this.code).subscribe((data:any)=>{
    console.log("data....",data)
    this.messageService.add({ severity: 'info', summary: 'Info', detail: `Nueva contraseña generada en el siguiente número de Whats App:  ${data.data
    .WhatsAppNumber}. Contáctenos en caso de no recibirla. Gracias`});
    },(err) => {
    console.log(err);
    this.messageService.add({ severity: 'error', summary: 'ERROR', detail: err.error.message });
    })


  }


  view_recordar() {

//alert(this.code);
   

      if(this.code!='')
      {

            if (this.recaptchaSuccess) {

            /// Code for REcaptcha Ends
            this.resetPassword=false;
            this.empleadService.resetPassword(this.code).subscribe((data:any)=>{
            console.log("data....",data)
            this.messageService.add({ severity: 'info', summary: 'Info', detail: `Nueva contraseña generada en el siguiente número de Whats App:  ${data.data
            .WhatsAppNumber}. Contáctenos en caso de no recibirla. Gracias`});
            },(err) => {
            console.log(err);
            this.messageService.add({ severity: 'error', summary: 'ERROR', detail: err.error.message });
            })

            }
            else {
            this.messageService.add({ severity: 'warn', summary: 'ERROR', detail: 'Por favor confirme reCaptcha!' });
            }


      }
      else{
        this.messageService.add({ severity: 'warn', summary: 'ERROR', detail: 'Enter Code!' });
      }

      



    

   




  }


}

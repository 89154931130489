<div>
    <p-table [value]="historyHorarioArray" [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5"
                [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} de {totalPages}">
        <ng-template pTemplate="header">
            <tr>
                <th>Fecha</th>
                <th>Entrada</th>
                <th>Salida</th>
                <th>Saldo</th>
                
                <th>Informe</th>
            <!--    <th>Localizacion</th>-->
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-historyHorario>
            <tr>
                <td>{{historyHorario.inicio | date: 'dd/MM/yyyy'}}</td> 
                <td>{{historyHorario.inicio | date: 'HH:mm'}}</td>
                <td>{{historyHorario.fin | date: 'HH:mm'}}</td>
                <td>{{historyHorario.saldo}}</td>
                
                <td>

                    <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-eye" class="p-button-outlined"
                    (click)="selectProjectDetail(historyHorario.idEmpleado, historyHorario.inicio, historyHorario.fin, historyHorario.idhorario)"></button>
                </td>
            <!--    <td>{{historyHorario.localizacion == null? '':historyHorario.localizacion}}</td>-->
            </tr>
        </ng-template>
    </p-table>
    <br>
            <br>
            <br>
            <br>
            <br>
</div>
